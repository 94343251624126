.header{
  height:500px;
}

.bg-header{
  background-image: url('./img/bg/header.jpg');
  background-position: center;
}


.bg-services{
  background-image: url('./img/bg/services.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}


.bg-contact{
  background-image: url('./img/bg/contact.jpg');
}


.bg-parrainage{
  background-image: url('./img/bg/parrainage.jpg');
}


.slide{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}